import GATSBY_COMPILED_MDX from "C:/Users/Richa/Documents/WWW/RTPDesign-G/projects/Nimbash/index.mdx";
import * as React from 'react';
import {Link, graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import {backArrow, gradientOverlay, projectBody, hero, heroTitle} from './projects.module.css';
import {Seo} from '../../components/seo';
const BlogPost = ({data, children}) => {
  const image = getImage(data.mdx.frontmatter.hero_image);
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, React.createElement("div", {
    className: hero
  }, React.createElement("div", {
    className: gradientOverlay
  }, React.createElement(GatsbyImage, {
    image: image,
    alt: data.mdx.frontmatter.hero_image_alt,
    style: {
      minHeight: '66vh'
    }
  })), React.createElement(Link, {
    to: "/projects"
  }, React.createElement("h1", {
    className: backArrow
  }, "«")), React.createElement("div", {
    className: heroTitle
  }, React.createElement("p", null, data.mdx.frontmatter.date), React.createElement("h1", null, data.mdx.frontmatter.title), React.createElement("h3", null, data.mdx.frontmatter.subtitle))), React.createElement("div", {
    className: projectBody
  }, children));
};
export const query = graphql`
    query ($id: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                subtitle
                date(formatString: "MMM D, YYYY")
                hero_image_alt
                hero_image {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`;
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.title
});
